import { ClerkLoading, SignIn } from "@clerk/nextjs";
import Image from "next/image";
import Link from "next/link";
import { Button } from "@/components/ui/button";

const LoadingState = () => (
  <div className="relative w-[400px] overflow-hidden rounded-lg bg-white p-4 shadow-lg">
    {/* Shimmer Effect Overlay */}
    <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-white/60 to-transparent" />

    {/* Loading Text - Moved to top and centered */}
    <div className="mb-6 flex items-center justify-center space-x-2">
      <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-blue-500" />
      <span className="text-sm font-medium text-gray-500">
        Loading sign in form
      </span>
    </div>

    {/* Header Skeleton */}
    <div className="space-y-3 pb-6">
      <div className="h-8 w-3/4 rounded-md bg-gray-300" />
      <div className="h-4 w-1/2 rounded-md bg-gray-300" />
    </div>

    {/* Input Fields Skeleton */}
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="h-5 w-24 rounded-md bg-gray-300" />
        <div className="h-12 w-full rounded-md bg-gray-300" />
      </div>
      <div className="space-y-2">
        <div className="h-5 w-24 rounded-md bg-gray-300" />
        <div className="h-12 w-full rounded-md bg-gray-300" />
      </div>
    </div>

    {/* Button Skeleton */}
    <div className="mt-6 h-12 w-full rounded-md bg-blue-200" />

    {/* Divider Skeleton */}
    <div className="my-6 flex items-center space-x-4">
      <div className="h-[2px] flex-1 bg-gray-300" />
      <div className="h-4 w-8 rounded-md bg-gray-300" />
      <div className="h-[2px] flex-1 bg-gray-300" />
    </div>

    {/* Social Buttons Skeleton */}
    <div className="space-y-4">
      <div className="h-12 w-full rounded-md bg-gray-300" />
      <div className="h-12 w-full rounded-md bg-gray-300" />
    </div>
  </div>
);

export default function SignInPage() {
  return (
    <div className="relative min-h-screen bg-white">
      {/* Background Image - Hidden on mobile */}
      <div className="absolute left-0 top-0 hidden h-full w-full select-none lg:block">
        <Image
          src="/images/login-background.png"
          alt="Login Background"
          className="h-full w-full object-cover"
          width={1920}
          height={1080}
          priority
          draggable={false}
        />
      </div>

      {/* Sign Up Button */}
      <div className="absolute right-[5%] top-4 z-10 flex items-center gap-4">
        <span className="text-sm text-gray-600">Not a member?</span>
        <a href="/sign-up">
          <Button variant="default" className="h-auto">
            Sign up now
          </Button>
        </a>
      </div>

      {/* Main Content */}
      <div className="relative flex min-h-screen items-center justify-center lg:justify-end lg:pr-[5%]">
        <div className="px-4">
          <ClerkLoading>
            <LoadingState />
          </ClerkLoading>
          <SignIn
            appearance={{
              layout: {
                socialButtonsPlacement: "bottom",
                socialButtonsVariant: "blockButton",
                unsafe_disableDevelopmentModeWarnings: true,
              },
              elements: {
                formButtonPrimary:
                  "bg-[#5956E9] hover:bg-[#4745bb] text-sm font-medium",
                card: "shadow-none",
                socialButtonsBlockButton: "border border-gray-200",
                formFieldLabel: "text-sm font-medium text-gray-900",
                formFieldInput: "rounded-md border-gray-200",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
